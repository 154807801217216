<template>
  <div class="wiki-page" v-scrollintoview="'Wiki'">
    <div class="view-container">
      <div class="wiki-page-content"
        :class="{'wiki-page-content-search': searchFlag}"
      >
        <div class="wiki-page-top">
          <div class="page-title">
            <h2 :class="{'title-link': searchFlag}" @click="reset">
              {{ $t('富港智库') }}
              <img v-if="searchFlag" src="@/assets/images/wiki/title-link.png" alt="" srcset="">
            </h2>
            <p>{{ $t('提交表单，即可获得专业客户经理一对一问题解答') }}</p>
          </div>
          <div class="search">
            <img src="@/assets/images/wiki/search.png" alt="" srcset="">
            <input
              type="text"
              v-model="searchValue"
              @input="changeSearchValue"
              @keyup.enter="search"
              :placeholder="$t('输入想查询问题关键词')">
            <div class="search-btn" @click="search">
              {{ $t('搜索') }}
            </div>
          </div>
        </div>

        <div class="artical-content">
          <!-- 有人工输入搜索时 并且 搜索内容不为空 隐藏以下部分 -->
          <div v-if="!searchFlag" class="artical-content-left">
            <ul>
              <li
                v-for="(item, index) in cateList"
                :key="index"
                :class="{ 'active': index === currentIndex }"
                @click="chooseCate(item, index)"
              >
                {{ item.name }}
              </li>
            </ul>
          </div>
          <div class="artical-content-list">
            <el-collapse v-model="activeNames" accordion>
              <div v-if="questionList && questionList.length">
                <el-collapse-item
                  v-for="(item, index) in questionList"
                  :key="index"
                  :title="item.questionTitle"
                  :name="index">
                  <div v-html="item.contentHtml" class="editor-content-view" />
                </el-collapse-item>
              </div>
              <div v-else class="no-data">
                <div class="img">
                  <img src="@/assets/images/wiki/placeholder.png" alt="">
                </div>
                <p class="text">{{ $t('暂无信息') }}</p>
              </div>
            </el-collapse>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      // 搜索文案
      searchValue: '',
      // 分类列表
      cateList: [],
      // 分类选中第几个
      currentIndex: 0,
      expandList: [0],
      // 问题列表
      activeNames: [],
      searchList: [],
      questionList: []
    }
  },
  created () {
    this.getQuestionTypeList()
  },
  computed: {
    ...mapGetters(['lang']),
    // 搜索判断
    searchFlag () {
      // 有人工输入搜索时 并且 搜索内容不为空 为true
      const { searchList } = this

      if (searchList.length > 0) {
        return true
      }

      return false
    }
  },
  watch: {
    lang () {
      this.searchValue = ''
      this.currentIndex = 0
      this.getQuestionTypeList()
    }
  },
  methods: {
    // 重置搜索内容
    reset () {
      this.searchValue = ''
      this.getQuestionList()
    },
    // 修改搜索内容
    changeSearchValue () {
      const { searchValue } = this
      if (!searchValue) {
        // 清空搜索内容时主动调一下api
        this.getQuestionList()
      }
    },
    // 获取分类列表
    getQuestionTypeList () {
      api.getQuestionTypeList().then(res => {
        if (res) {
          this.cateList = res

          this.getQuestionList()
        }
      })
    },
    // 获取问题列表
    getQuestionList () {
      const { cateList, currentIndex, searchValue } = this

      const data = {
        typeNum: !searchValue && cateList[currentIndex] ? cateList[currentIndex].typeNum : '',
        key: searchValue || ''
      }
      api.getQuestionList(data).then(res => {
        if (res) {
          // 该搜索结果用来判断是否展示分类
          if (searchValue) {
            // 如果有搜索文案 - 存储搜索结果
            this.searchList = res || []
          } else {
            // 没有搜索文案 - 删除搜索结果
            this.searchList = []
          }

          if (searchValue && res.length <= 0) {
            this.$toast({
              message: this.$t('搜索结果为空，请重新搜索')
            })
            return
          }
          this.questionList = res || []

          this.$nextTick(() => {
            // 默认展开第一条
            if (!this.searchFlag) {
              this.activeNames = [0]
            } else {
              // 如果搜索的话，则将展开置为空
              this.activeNames = []
            }
          })
        }
      })
    },
    // 搜索
    search () {
      this.getQuestionList()
    },
    // 选择分类
    chooseCate (e, index) {
      this.currentIndex = index
      // 清空搜索文案
      this.searchValue = ''
      this.getQuestionList()
    },
    // 是否展开
    ifExpanded (index) {
      const { expandList } = this

      if (expandList.includes(index)) {
        return true
      }
      return false
    },
    // 展开/收起
    chooseArtical (index) {
      const { expandList } = this
      const findIndex = expandList.findIndex(item => item === index)

      if (findIndex > -1) {
        expandList.splice(findIndex, 1)
      } else {
        expandList.push(index)
      }
    }
  }
}
</script>
<style lang="less">
  .artical-content-list {
    width: 100%;
    .el-collapse {
      border-top: none;
      border-bottom: none;

      ::v-deep .el-collapse-item {
        img {
          max-width: 100%;
        }
      }

      .el-collapse-item {
        padding-top: 30px;

        &:nth-last-child(1) {
          .el-collapse-item__header {
            border-bottom-width: 0;
          }
          .el-collapse-item__wrap {
            border-bottom-width: 0;
          }
        }

        &:nth-child(1) {
          padding-top: 0;
        }

        .el-collapse-item__content {
          font-size: 14px;
          line-height: 24px;
          color: #7C7C85;
          padding-bottom: 25px;
        }
      }

      .el-collapse-item__header {
        color: #000;
        font-size: 18px;
        position: relative;
        display: block;
        height: auto;
        min-height: 20px;
        line-height: inherit;
        padding-bottom: 15px;

        .el-collapse-item__arrow {
          display: none;
        }

        &::before {
          content: '';
          font-size: 6px;
          position: absolute;
          left: -16px;
          top: 6px;
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background-color: #000;
        }
      }
    }

    .no-data {
      padding-top: 20px;
      > .img {
        width: 280px;
        height: 280px;
        margin: 0 auto;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      .text {
        font-size: 14px;
        font-family: Source Han Sans CN-Light, Source Han Sans CN;
        font-weight: 300;
        color: #1A1A1A;
        line-height: 14px;
        text-align: center;
      }
    }
  }

  @media screen and (max-width: @mobileWidth) {
    .artical-content-list {
      .el-collapse {
        border-top: 0;

        .el-collapse-item {
          padding-top: 18px;

          &:nth-last-child(1) {
            .el-collapse-item__header {
              border-bottom-width: 0;
            }
            .el-collapse-item__wrap {
              border-bottom-width: 0;
            }
          }

          &:nth-child(1) {
            padding-top: 0;
          }

          .el-collapse-item__content {
            font-size: 12px;
            line-height: 24px;
            color: #7C7C85;
            padding-bottom: 12px;
          }
        }

        .el-collapse-item__header {
          color: #000;
          font-size: 14px;
          position: relative;
          display: block;
          height: auto;
          min-height: 20px;
          line-height: inherit;
          padding-bottom: 10px;

          .el-collapse-item__arrow {
            display: none;
          }

          &::before {
            content: '';
            width: 0;
          }
        }
      }
    }

    .no-data {
      padding-top: 20px;
      > .img {
        width: 140px;
        height: 140px;
        margin: 0 auto;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      .text {
        font-size: 12px;
        font-family: Source Han Sans CN-Light, Source Han Sans CN;
        font-weight: 300;
        color: #1A1A1A;
        line-height: 12px;
        text-align: center;
      }
    }
  }
</style>
<style lang="less">
.wiki-page {
  background-color: #F7F8FA;
  padding: 98px 0 230px;

  .wiki-page-content {
    width: 100%;
    margin: 0 auto;
    padding: 60px 30px 56px;
    border: 1px solid #eee;
    background-color: #fff;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;

    &.wiki-page-content-search {
      .artical-content .artical-content-list {
        margin-left: 0;
      }
    }

    .artical-content {
      margin-top: 60px;
      display: flex;

      .artical-content-list {
        flex: 1;
        margin-left: 45px;

        ul {
          width: 100%;

          li {
            padding-bottom: 30px;
            border-bottom: 1px solid rgba(0,0,0,0.16);
            margin-bottom: 30px;

            > div {
              font-size: 14px;
              font-weight: 300;
              color: #7C7C85;
              line-height: 24px;
              margin-top: 15px;
              display: none;
            }

            &.active div {
              display: block;
            }

            h2 {
              color: #000;
              font-size: 18px;
              position: relative;
              cursor: pointer;

              &::before {
                content: '';
                font-size: 6px;
                position: absolute;
                left: -16px;
                top: 6px;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: #000;
              }
            }

            &:nth-last-child(1) {
              margin-bottom: 0;
            }
          }
        }
      }

      .artical-content-left {
        width: 320px;
        padding-right: 20px;
        border-right: 1px solid rgba(0, 0, 0, 0.16);

        ul {
          width: 300px;

          li {
            width: 100%;
            height: 70px;
            padding: 0 20px;
            display: flex;
            align-items: center;
            background-color: transparent;
            font-size: 18px;
            color: rgba(0, 0, 0, 0.85);
            cursor: pointer;

            &.active {
              background-color: #F3F3F7;
            }
          }
        }
      }
    }

    .wiki-page-top {
      display: flex;
      align-items: flex-end;

      .search {
        width: 560px;
        height: 48px;
        background-color: #F9F9FC;
        border-radius: 4px 4px 4px 4px;
        border: 1px solid #D4D5DF;
        display: flex;
        align-items: center;
        padding-left: 14px;

        img {
          width: 14px;
          height: 14px;
        }

        input {
          margin: 0 14px;
          flex: 1;
          color: #1A1A1A;
          font-size: 14px;
          background-color: transparent;

          &::placeholder {
            color: #C2C2CA;
          }
        }

        .search-btn {
          width: 80px;
          height: 48px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 14px;
          background: linear-gradient(270deg, #83A6FF 0%, #5A78FF 100%);
          border-radius: 4px 4px 4px 4px;
          margin-left: auto;
          cursor: pointer;
        }
      }

      .page-title {
        margin-right: 58px;
        width: 312px;

        h2 {
          font-size: 30px;
          font-weight: 600;
          color: #000;
          margin-bottom: 20px;

          &.title-link {
            text-shadow: 0px 4px 10px #3662EC;
            display: flex;
            cursor: pointer;

            img {
              display: block;
              width: 40px;
              height: 40px;
              margin-top: 5px;
            }
          }
        }

        p {
          color: #1A1A1A;
          font-size: 14px;
          line-height: 18px;
        }
      }
    }
  }
}

@media screen and (max-width: @mobileWidth) {
  .wiki-page {
    background-color: #F7F8FA;
    padding: 30px 0 80px;

    .wiki-page-content {
      width: 100%;
      padding: 60px 15px 30px;
      position: relative;

      &.wiki-page-content-search {
        padding-top: 20px;
      }

      .artical-content {
        margin-top: 25px;

        .artical-content-list {
          flex: 1;
          margin-left: 0;

          ul {
            width: 100%;

            li {
              padding-bottom: 30px;
              border-bottom: 1px solid rgba(0,0,0,0.16);
              margin-bottom: 30px;

              > div {
                font-size: 14px;
                font-weight: 300;
                color: #7C7C85;
                line-height: 24px;
                margin-top: 15px;
                display: none;
              }

              &.active div {
                display: block;
              }

              h2 {
                color: #000;
                font-size: 18px;
                position: relative;
                cursor: pointer;

                &::before {
                  content: '';
                  font-size: 6px;
                  position: absolute;
                  left: -16px;
                  top: 6px;
                  width: 4px;
                  height: 4px;
                  border-radius: 50%;
                  background-color: #000;
                }
              }

              &:nth-last-child(1) {
                margin-bottom: 0;
              }
            }
          }
        }

        .artical-content-left {
          position: absolute;
          top: 20px;
          left: 0;
          width: 100%;
          padding-right: 15px;
          padding-left: 15px;
          border-right: 0;

          ul {
            width: 100%;
            padding-bottom: 18px;
            overflow-x: scroll;
            display: flex;

            li {
              flex-shrink: 0;
              width: auto;
              height: auto;
              margin-right: 22px;
              padding: 0;
              display: flex;
              align-items: center;
              background-color: transparent;
              font-size: 14px;
              color: #000;

              &:nth-last-child(1) {
                margin-right: 0;
              }

              &.active {
                background-color: transparent;
                color: #313CB0;
                position: relative;

                &::after {
                  content: '';
                  position: absolute;
                  left: 50%;
                  transform: translate(-50%, 0);
                  bottom: -7px;
                  width: 35px;
                  height: 2px;
                  background-color: #313CB0;
                  border-radius: 40px;
                }
              }
            }
          }
        }
      }

      .wiki-page-top {

        .search {
          width: 100%;
          height: 36px;
          background-color: #F9F9FC;
          border-radius: 4px;
          border: 1px solid #D4D5DF;
          display: flex;
          align-items: center;
          padding-left: 10px;

          img {
            width: 14px;
            height: 14px;
          }

          input {
            margin: 0 5px;
            flex: 1;
            color: #1A1A1A;
            font-size: 12px;
            background-color: transparent;

            &::placeholder {
              color: #C2C2CA;
            }
          }

          .search-btn {
            width: 48px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 12px;
            background: linear-gradient(270deg, #83A6FF 0%, #5A78FF 100%);
            border-radius: 4px;
            margin-left: auto;
            cursor: pointer;
          }
        }

        .page-title {
          display: none;
        }
      }
    }
  }
}
</style>
